body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Sans", sans-serif;
}
#pdfContent {
  width: 100%; /* Use the full width of the container */
  min-height: 297mm;
}
.pdf-container {
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden; /* To prevent content from spilling out */
}
@media print {
  #pdfContent {
    width: 100%;
    height: auto;
    margin: 0;
    box-shadow: none;
  }
}
@media print {
  .MuiTypography-root {
    vertical-align: middle; /* Or adjust as needed */
  }
}
.rotated-text {
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
}
.page-break {
  page-break-before: always;
  break-before: always;
}
.centered-content {
  display: flex!important;
  justify-content: center;
  align-items: center;
}
.adorment {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
